.features {
  width: 100%;
  overflow: hidden;

  @include minWidth600{
    background-color: #111722;
  }
}

.features__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 0.8rem;
  border-radius: 3rem;
  background-color: #111722;


  @include minWidth600{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 4rem;
    border-radius: 0;
  }
  @include minWidth1024{
    padding: 8rem 4rem;
    max-width: 116.2rem;
    margin: 0 auto;
  }

}
.feature {
  display: flex;
  justify-content: flex-start;
  height: 9.2rem;
  padding: 1.7rem 1.6rem;
  @include minWidth600{
    width: 36rem;
    margin-bottom:3rem;
  }
  @include minWidth1024{
    margin-bottom: 7rem;
  }

}
.feature_icon {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  margin-right: 1.6rem;
}

.feature__title {
  font-size: 2.4rem;
  font-weight: 700;
  text-align: left;
  color: #fff;
}
