html {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-size: 10px;
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

main {
  background: $main-background-color;
  overflow: hidden;
}

h2 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.13;
  text-align: left;
  color: $color-title-primary;
}

p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  color: $color-primary;
}

.text_mobile {
  display: inline-block;
}
.text_desktop {
  display: none;
}

@include minWidth1024 {
  .text_mobile {
    display: none;
  }
  .text_desktop {
    display: inline-block;
  }
}
