.get-app {
  position: relative;
  width: 100%;
  height: 57.7rem;
  flex-grow: 0;
  padding: 35px 0 0;
  background-color: #fff;
  z-index: 0;
}

.get-app__background1 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/images/plants@2x.webp) 47% bottom/78rem auto
    no-repeat;
  z-index: 4;
  pointer-events: none;

  @include minWidth600 {
    width: 197.9rem;
    left: 50%;
    transform: translateX(-45%);
    background: url(../assets/images/plants-desctop.png) center -3%/197.9rem auto
      no-repeat;
  }
}

.get-app__background2 {
  position: absolute;
  left: 0;
  top: 3.5rem;
  width: 66%;
  height: 42rem;
  mix-blend-mode: darken;
  -webkit-filter: blur(465.7px);
  filter: blur(465.7px);
  background-color: #f0ff70;
  z-index: 3;
  pointer-events: none;
}

.get-app__background3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 27.7rem;
  mix-blend-mode: darken;
  background-image: linear-gradient(
    to top,
    rgba(52, 205, 159, 0.7) -13%,
    rgba(2, 87, 73, 0)
  );
  z-index: 5;
  pointer-events: none;
}

.get-app-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 34.2rem;
  height: 30.7rem;
  justify-content: center;
  align-items: flex-start;
  margin: 1.4rem auto 0;
  z-index: 3;

  padding: 4rem 1rem 4rem;
  border-radius: 2.8rem;
  -webkit-backdrop-filter: blur(5.4rem);
  backdrop-filter: blur(5.4rem);
  background-image: radial-gradient(
    circle at 92% 5%,
    rgba(#25af82, 0.6),
    rgba(#31c564, 0.6) 95%
  );

  @include minWidth600 {
    max-width: 59.4rem;
    margin-top: 4.8rem;
  }
}

.get-app__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.get-app__icon {
  width: 4.7rem;
  height: 4.7rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.get-app__title {
  margin-top: 0.5rem;
  font-size: 4.8rem;
  font-weight: bold;
  letter-spacing: -0.19rem;
}

.get-app__subtitle {
  margin-top: 0.3rem;
  font-size: 2rem;
}

.app-store_get-app {
  position: absolute;
  width: 17.6rem;
  height: 5.1rem;

  bottom: 5rem;
  z-index: 20;
}
