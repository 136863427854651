@import "./variables";

.header {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header-wrapper {
  width: 100%;
  height: 72rem;
  flex-grow: 0;
  background: url(../assets/images/header@2x.webp) bottom -2.6rem right -2.7rem/54rem auto no-repeat, radial-gradient(circle at 22% 9%, #fff 3%, #b3f9d6 55%, #bcfbbd 71%, #06a162 113%);

  @include ratio3x {
    background: url(../assets/images/header@3x.webp) bottom -2.6rem right -2.7rem/54rem auto no-repeat, radial-gradient(circle at 22% 9%, #fff 3%, #b3f9d6 55%, #bcfbbd 71%, #06a162 113%);
  }

  @include minWidth1024 {
    background: url(../assets/images/header-desctop.webp) bottom  right/54vw auto no-repeat, radial-gradient(circle at 22% 9%, #fff 3%, #b3f9d6 55%, #bcfbbd 71%, #06a162 113%);
  }
  @include minWidth1440 {
    background: url(../assets/images/header-desctop.webp) bottom  right/78rem auto no-repeat, radial-gradient(circle at 22% 9%, #fff 3%, #b3f9d6 55%, #bcfbbd 71%, #06a162 113%);
  }
}

.header-inner {
  position: relative;
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
}


// Rates section

.rates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7.3rem;
  padding: 1.4rem $padding-content 1.5rem 5.8rem;
  background-color: #181C1F;

  font-size: 1.6rem;
  font-weight: 600;
}
.rates__close {
  position: absolute;
  left: $padding-content;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rates__logo {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  .logo__icon {
    margin-right: 1.4rem;
  }
}
.logo__icon {
  width: 4.1rem;
  height: 4.1rem;
  margin-right: 1rem;
  object-fit: contain;
  border-radius: 0.87rem;

  @include minWidth600 {
    width: 4.7rem;
    height: 4.7rem;
    margin-right: 2rem;
  }
}
.logo__info {
  text-align: left;
}
.logo__title {
  font-size: 1.9rem;
  font-weight: 700;
  color: $color-title-primary;
  @include minWidth600 {
    font-size: 2.3rem;
    margin-bottom: 0.2rem;
  }
}
.logo__subtitle {
  font-size: 0.9rem;
  font-weight: 500;
  color: $color-primary;
  text-transform: uppercase;
}

.rates__get {
  background-color: #fff;
  width: 5.8rem;
  height: 3rem;
  border-radius: 1.5rem;
  font-size: 1.6rem;
  font-weight: 600;

  &:hover {
    transform: scale(0.98);
    transform-origin: center;
  }
}

// Download section

.download {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 6.4rem;
  padding: 1.1rem $padding-content;



  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e7ebfb;
  }

  @include minWidth600 {
    height: 7.2rem;
    margin: 0 auto;
    &:after {
      width: 100vw;
    }
  }


}

.app-store {
  width: 14.5rem;
  height: 4.2rem;



  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &_header {
    width: 18rem;
    height: 5.2rem;
    margin-top: 2.7rem;

    @include minWidth600{
      width: 20.9rem;
      height: 5.86rem;
    }

    @include minWidth1024{
      margin-top: 5.1rem;
    }
  }

  &:hover {
    transform: scale(0.98);
    transform-origin: center;
  }
}

.download .app-store {
 max-width: 40%;


}

.header__content {
  width: 100%;
  max-width: 39rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.95rem 2.4rem 0;

  @include minWidth600{
    max-width: 50rem;
    padding: 2.95rem 5rem 0;
  }

  @include minWidth1024{
    max-width: none;
    padding-top: 10.4rem;
  }
}

.header__title {
  @include minWidth600{
    font-size: 5rem;
  }
  @include minWidth1024{
    font-size: 8rem;
  }
}
.header__info {
  margin-top: 1rem;
  max-width: 31rem;

  @include minWidth600{
    font-size: 1.8rem;
    max-width: 46rem;
    margin-top: 2.2rem;
  }
}

.header__features {
  position: absolute;
  bottom: 2.1rem;
  left: 2.5rem;
  width: 19.6rem;
  height: 17.8rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include minWidth600 {
    bottom: 2.1rem;
    right: 17.5rem;
    left: auto;
  }
  @include minWidth1024 {
    width: 24rem;
    height: auto;
    bottom: 8.2rem;
    right: 25vw;
    left: auto
  }
}
