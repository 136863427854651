.advantages {
  width: 100%;
  padding: 2rem $padding-content 4rem;

  background-color: $secondary-background-color;
  border-radius: 0 0 3rem 3rem;

  @include minWidth600{
    padding: 4rem;
    border-radius: 0;
  }
  @include minWidth1024{
    padding: 6rem 4rem 6rem;
  }
}
.advantage {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  padding: 3rem 2.4rem 0;
  border-radius: 3rem;
  background-color: #fff;
  overflow: hidden;

  @include minWidth600{
    max-width: 116.2rem;
  }
  @include minWidth1024{
    padding: 4.2rem 2.4rem 0;
  }

  & + & {
    margin-top: 2rem;

    @include minWidth600{
      margin-top: 3rem;
    }
  }

}

.advantage__image {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &_why {
    margin-top: -1.4rem;
    width: 116%;
    max-width: 42.35rem;

    @include minWidth600{
      margin-top: -3.4rem;
      max-width: 38rem;
    }
  }

  &_care {
    margin-top: 6rem;
    width: 116%;
    max-width: 26.6rem;
    z-index: 1;

    @include minWidth1024{
      position: relative;
      max-width: 29.5rem;
      bottom: -3.8rem;
      margin-top: -2rem;
    }
  }

  width: 116%;
}
.advantage__care-icons {
  position: absolute;
  bottom: 11rem;
  left: 50%;
  transform: translateX(-50%);
  width: 42.33rem;
  height: 40rem;


  background: url(../assets/images/advantage-care-icons@2x.webp) center/contain no-repeat;

  @include ratio3x {
    background: url(../assets/images/advantage-care-icons@3x.webp) center/contain no-repeat;
  }


  @include minWidth1024{

    background: url(../assets/images/advantage-care-desctop-icons.png) center/contain no-repeat;
    width: 90%;
    max-width: 78.5rem;
    bottom: -8rem;
    z-index: 0;
  }
}

.advantage__title {
  font-weight: 700;

  @include minWidth600{
    max-width: 50rem;
    margin:  0 auto;
    text-align: center;
    font-size: 3.8rem;
  }
  @include minWidth1024{
    max-width: 65rem;
    font-size: 5rem;
  }
}
.advantage__info {
  font-weight: 400;
  font-size: 1.9rem;
  margin-top: 0.5rem;

  @include minWidth600{
    max-width: 50rem;
    margin:  .5rem auto 0;
    text-align: center;
    font-size: 1.8rem;
  }
  @include minWidth1024{
    max-width: 53rem;
    margin-top: 1rem;
    font-size: 2rem;
  }
}

.button-try {
  width: 100%;
  margin-top: 2.6rem;

  button {
    width: 100%;
    max-width: 35.8rem;
    height: 5.6rem;
    margin: 0 auto;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2.4rem;
    border-radius: 1.8rem;
    background-color: #03cc8e;


  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: #fff;

  &:hover {
    transform: scale(0.985);
    transform-origin: center;
  }
  }
}
