.how-it-works {
  width: 100%;
  margin-top: 6.9rem;

  @include minWidth600{
    margin-top: 5.2rem;
  }
}

.how-it-works-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works__image {
  width: 100%;
  overflow: hidden;
  max-width: 77.6rem;

  img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-width: 53.9rem;
    height: auto;
  }
}
.how-it-works__title {
  margin-top: 4.2rem;
  text-align: center;

  @include minWidth600{
    margin-top: 0rem;
    font-size: 5rem;
  }

}
.how-it-works__qr {
  width: 12.1rem;
  height: 12.1rem;
  margin-top: 1.5rem;

  img {
    width: 100%;
    height: auto;
  }
}
