@mixin minWidth600 {
  @media only screen and (min-width: 600px) {
    @content;
  }
}
@mixin minWidth1024{
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
@mixin minWidth1440{
  @media only screen and (min-width: 1440px) {
    @content;
  }
}


@mixin ratio3x {
  /* @3x Images (Pixel Ratio of 2.25+) */
  @media only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx) {
    @content;
  }
}
