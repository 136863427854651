.faq {
  position: relative;
  width: 100%;
  margin-top: 5.5rem;
  padding: 8rem 2.4rem;
  border-radius: 3rem;
  background-color: $secondary-background-color;
  z-index: 10;

  @include minWidth1024{
    max-width: 120rem;
    padding: 8rem 12rem;
  border-radius: 4rem;
  margin: 5.5rem auto 0;
  }


}

.faq-wrapper {
  display: flex;
  flex-direction: column;

  @include minWidth1024{
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2rem;
  }

}

.faq__title {
  font-size: 5rem;
  font-weight: 700;

  @include minWidth1024{
    width: 44rem;
  }
}

.faq__list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 3.5rem;

  @include minWidth1024{
    width: 44rem;
    margin-top: 0;
  }
}

.question {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 7.4rem;
  justify-content: flex-start;
  align-items: center;

  // padding: 2.5rem 1.6rem;
  border-radius: 1.7rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
  background-color: #f8fafa;

  & + & {
    margin-top: 1.2rem;
  }

  details {
    width: 100%;
  }

  details[open]>.question__title:before {
    background-image: url(../assets/icons/minus.svg);
  }

  details[open]>.question__title:first-child:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 96%;
    height: 1px;
    background-color: #cddedb;
}



}

.question__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 7.4rem;
  padding: 0 3.2rem 0 5.6rem ;

  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
  color: $color-title-primary;

  &:before {
    content: "";
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(../assets/icons/plus.svg) center/contain no-repeat;
  }
}

// .question__answer {
//   font-size: 1.9rem;
//   padding: 2rem;
//   text-align: left;
//   color: #44526C;
//   font-weight: 400;

//   text-indent: 20px;
// }

.question__answer {
  font-size: 1.9rem;
  padding: 2rem;
  padding-left: 5.6rem;
  text-align: left;
  color: #44526C;
  font-weight: 400;
}
