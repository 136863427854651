:root {
  --slideArticleWidth: 0;
}

$color-title-primary: #111722;
$color-primary: #44526C;
$color-logo-subtitle: #455C87;
$color-secondary: #000;

$padding-content: 1.6rem;


$header-color: #222024;
$description-color: #b9babe;
$main-background-color: #fff;
$secondary-background-color: #e7ebfb;
$feature-background-color: #099562;
$dark-background-color: #232126;
$dark-bg-color: #2b282f;
$border-color: #414245;
$purple: #a74eef;
$red: #ff4330;
$skyey: #2985ff;
$orange: #ff8100;
