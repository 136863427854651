*:not(input, button, a) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

html,
body,
p {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  color: inherit;
  background: rgba(0, 0, 0, 0);
}
button {
  padding: 0;
  font-family: "Arimo", sans-serif;
  border: none;
  background: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
cite {
  font-style: normal;
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

details > summary::-webkit-details-marker {
  display: none;
}
