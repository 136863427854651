.footer {
  position: relative;
  width: 100%;
  height: auto;
  flex-grow: 0;
  padding: 3.1rem $padding-content 2rem;
  background-color: #334566;
  z-index: 12;


  @include minWidth1024{
    padding: 4rem 6.4rem 2rem;
    height: 18.1rem;
  }
}

.footer-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  @include minWidth1024{
   max-width: 131.2rem;
   margin: 0 auto;
   height: 100%;
   }

}

.footer__logo {
  margin-top: 4.4rem;

  .logo__icon {
    // width: 4.7rem;
    // height: 4.7rem;
    // margin-right: 1.2rem;
  }
  .logo__title {
    // font-size: 2.7rem;
  color: #fff;
  }
  .logo__subtitle {
    // font-size: 1.3rem;
    // text-align: left;
    color: #dde3ee;
  }

  @include minWidth1024{
   position: absolute;
   left: 0;
   top: 0;
   margin: 0;
  }

}

.footer__info {
  margin-top: 2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #bbc7dd;
  height: auto;
  min-height: 6.6rem;

}

.footer__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 5rem;
  margin-top: 0rem;

  @include minWidth1024{
    position: absolute;
    width: auto;
    right: 0;
    top: 0;
   }

}

.app-store_footer {
width: 15.7rem;
height: 4.55rem;
}

.footer_navigation {
margin-top: 4.9rem;

@include minWidth1024{
  position: absolute;
  width: auto;
  right: 0;
  bottom: 0;
 }
}
.navigation__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include minWidth1024{
   flex-direction: row;
   }
}
.navigation__link {
  height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #dde3ee;

  @include minWidth1024{
    flex-direction: row;
    }

  & + & {
    margin-top: 2.2rem;

    @include minWidth1024{
      margin-top: 0;
      margin-left: 4.7rem;
    }
  }

  &:hover {
    color:#fff;
  }
}

.footer__copyright {
  margin-top: 5rem;
  height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #99abcc;

  @include minWidth1024{
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
